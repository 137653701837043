import React, { useEffect, useContext, lazy, Suspense } from 'react';

import { useRouter } from "next/router"
import { useTranslation } from 'next-i18next'
import { Chair } from "@styled-icons/material-outlined/Chair"
import Link from "next/link"

const HeroWidget = props => {
    const router = useRouter()
    const { t } = useTranslation()

    const onCreate = () => {
        router.push(`/new-board`)
    }

    return (
        <>
        <div className="w-full bg-gray-50 md:pt-20 pb-32 md:pb-40">
        <div className="lg:flex items-center h-full xl:max-w-screen-2xl xl:mx-auto">
          <div className="w-full lg:w-3/5 xl:w-2/5 justify-center mx-auto max-w-screen-xl pt-16 px-8 lg:pl-20 py-10">
            <h1 className="text-4xl font-serif md:text-5xl lg:text-6xl text-pink-900 opacity-80 font-bold mb-8">
              {t("hero.ai-title")}
            </h1>
            <p className="text-lg leading-relaxed text-gray-900 opacity-80 pr-10 md:pr-20">
              {t('hero.ai-description')}
            </p>
            <div className="flex items-center gap-3 flex-wrap mt-10">
              <Link href="/ai-generator" passHref>
              <a
                className="lg:text-xl bg-pink-900 hover:bg-pink-700 py-3 px-6 text-white rounded-full focus:outline-none"
              >
                {t("hero.ai-generator")}
              </a>
              </Link>
            </div>
          </div>
          <div className='w-full lg:w-2/5 xl:w-3/5 justify-center lg:flex lg:flex-col px-8 lg:px-10 xl:px-20 py-8 lg:pr-20 xl:py-10'>
            <div>
              <video
                  className="rounded-lg shadow-xl bg-white"
                  autoPlay
                  loop
                  muted
                  playsInline
                  poster="/assets/Mood-Board-Generator-Header-V2_720.jpg"
                  height="720"
                  width="1280"
                >
                  <source src="/assets/Mood-Board-Generator-Header-V2_720.mp4" type="video/mp4" />
                </video>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray-50 pb-12 md:pb-20">

      <div className="container mx-auto max-w-screen-lg text-center">
            <div className='text-center mb-12 px-8'>
              <h2 className="font-serif text-3xl md:text-4xl font-semibold">{t("hero.title")}</h2>
              <h3 className="mt-4 text-lg opacity-80 leading-relaxed text-gray-600">{t('hero.main-description')}</h3>
            </div>
            <div className="items-center">
              <div className="w-full px-8 pt-8 md:pt-0">
              <video
                className="rounded-lg shadow-xl"
                autoPlay
                loop
                muted
                playsInline
                poster="/assets/MoodBoardly-maroccan-style.jpg"
              >
                <source src="/assets/MoodBoardly-maroccan-style.mp4" type="video/mp4" />
              </video>
              </div>
              
            <div className="flex items-center justify-center gap-3 flex-wrap mt-10">
              <button
                onClick={onCreate}
                data-testid="add-board"
                className="lg:text-xl bg-pink-900 hover:bg-pink-700 py-3 px-6 text-white rounded-full focus:outline-none"
              >
                {t("hero.create-board")}
              </button>
            </div>
            </div>
          </div>
        
      </div>
      <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto max-w-screen-lg text-center">
            <div className='text-center mb-12 px-8'>
              <h2 className="font-serif text-3xl md:text-4xl font-semibold">{t('hero.add-own-product')}</h2>
              <h3 className="mt-4 text-lg opacity-80 leading-relaxed text-gray-600">{t('hero.add-own-description')}</h3>
            </div>
            <div className="items-center">
              <div className="w-full px-8 pt-8 md:pt-0">
                <video
                  className="rounded-lg shadow-xl"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="/assets/add-your-own-products.mp4" type="video/mp4" />
                </video>
              </div>
              
            </div>
          </div>
        </section>

        <section className="relative py-20 md:pb-40">
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap-reverse md:flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
              <video
                  className="rounded-lg shadow-xl"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="/assets/remove-bg.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-900 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-200">
                    <Chair className="w-7 h-7" />
                  </div>
                  <h3 className="font-serif text-3xl font-semibold">{t('hero.remove-bg-title')}</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    {t('hero.remove-bg-description')}
                  </p>
                  <ul className="list-none mt-6 mb-8">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            {t('hero.remove-bg-oneclick')}
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            {/* <i className="fab fa-html5"></i> */}
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">{t('hero.remove-bg-clever')}</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <button
                  onClick={onCreate}
                  data-testid="add-board"
                  className="bg-pink-900 hover:bg-pink-700 py-2 px-4 text-white rounded-full focus:outline-none"
                >
                  Design now
                </button>
              </div>
            </div>
          </div>
        </section>
        </>
    )
};


export default HeroWidget; 